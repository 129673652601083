* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  
}

:root {
  --primary: #FFD908;
  --black: #050505;
  --blackLighter: #9E9E9E;
  --grayLight: #F5F5F5;
  --grayMedium: #e5e5e5; 
  --white: #FFFFFF;
  --frontEnd: #0099CC;
  --backEnd: #00C86F;
}

html,
body {
  margin: 0;
  padding: 0;
 
}

a {
  color: inherit;
} 

#root {
  min-height: calc(100vh - var(--bodyPaddingTop));
  display: flex;
  flex-direction: column;
  
}